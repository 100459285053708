/* TODO: move to styleguide */
html, body {
  scrollbar-width: 0.8rem;
}

::-webkit-scrollbar {
  height: 0.8rem;
  width: 0.8rem;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #384147;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: transparent;
}
